import ListNotification from '@components/notification/ListNotification';
import { useModal } from '@providers/ModalProvider';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import { AuthContextValue, useAuthState } from '@src/providers/AuthProvider';
import {
  NotificationContextValue,
  useNotificationState,
} from '@src/providers/NotificationProvider';
import { AuthScreenType } from '@src/utils/enums';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { MouseEvent, useState } from 'react';

const Menu = dynamic(() => import('@mui/material/Menu'));
const Authentication = dynamic(() => import('@components/modal/authentication/Authentication'));

// Assets
import IconInboxSVG from '/public/ic-inbox-noti.svg';
import IconNotificationSVG from '/public/ic-notification.svg';

export default function NotificationButton() {
  /* Hooks */
  const { setModalContent } = useModal();
  const { isAuthenticated } = useAuthState() as AuthContextValue;
  const { hasNewNotification, updateLastTimeOpenNotification } =
    useNotificationState() as NotificationContextValue;
  const { isMobile } = useWindowDimensions();

  const router = useRouter();

  const [anchorElForNotification, setAnchorElForNotification] = useState<null | HTMLElement>(null);
  const isNotificationMenuOpen = Boolean(anchorElForNotification);

  /* Functions */
  const handleOpenNotification = (event: MouseEvent<HTMLElement>) => {
    if (!isAuthenticated) {
      setModalContent(<Authentication defaultScreenType={AuthScreenType.MAIN} />);
      return;
    }
    if (router.pathname === '/notifications') return;
    if (isMobile) {
      router.push('/notifications');
    } else {
      setAnchorElForNotification(event.currentTarget);
    }
    updateLastTimeOpenNotification();
  };

  const handleCloseNotificationMenu = () => {
    setAnchorElForNotification(null);
  };

  /* Views */
  return (
    <div>
      {/* If user is not authenticated, show the login modal */}
      {/* Desktop button */}
      <button
        onClick={handleOpenNotification}
        className="md:flex hidden shrink-0 p-1.5 hover:bg-greyscale-g1 rounded-full"
      >
        <div className="relative shrink-0">
          <div className="flex items-center justify-center">
            <IconNotificationSVG />
          </div>
          {hasNewNotification && (
            <div className="absolute -top-1 -right-0 text-m font-semibold">
              {/* <Image alt="Notification" src="/ic-inbox-noti.svg" width={10} height={10} /> */}
              <div className="flex items-center justify-center w-2 h-2">
                <IconInboxSVG />
              </div>
            </div>
          )}
        </div>
      </button>
      {/* Mobile button */}
      <button onClick={handleOpenNotification} className="md:hidden flex shrink-0">
        <div className="relative shrink-0">
          <div className="flex items-center justify-center h-8 w-8 rounded-full border border-greyscale-g3">
            <div className="flex items-center justify-center">
              <IconNotificationSVG viewBox="0 0 24 24" className="w-5 h-5" />
            </div>
          </div>
          {hasNewNotification && (
            <div className="absolute top-1 right-1 text-m font-semibold">
              <div className="flex items-center justify-center w-2 h-2">
                <IconInboxSVG />
              </div>
            </div>
          )}
        </div>
      </button>

      {!isMobile && (
        <Menu
          anchorEl={anchorElForNotification}
          id="notification-menu"
          open={isNotificationMenuOpen}
          onClose={handleCloseNotificationMenu}
          onClick={handleCloseNotificationMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 0px 6px rgba(0,0,0,0.20))',
              mt: 1,
              borderRadius: '8px',
              width: '352px',
            },
          }}
        >
          <ListNotification />
        </Menu>
      )}
    </div>
  );
}
