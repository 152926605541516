import {
  CreateNotificationInput,
  CreateNotificationMutation,
  CreateNotificationMutationVariables,
  GetNotificationQuery,
  GetNotificationQueryVariables,
  ModelSortDirection,
  Notification,
  NotificationByUserIdPublicationDateTimeQuery,
  NotificationByUserIdPublicationDateTimeQueryVariables,
  UpdateNotificationInput,
  UpdateNotificationMutation,
  UpdateNotificationMutationVariables,
} from '@src/API';
import { createNotification, updateNotification } from '@src/graphql/mutations';
import { getNotification, notificationByUserIdPublicationDateTime } from '@src/graphql/queries';
import { ListData } from '@src/libs/models';
import { API } from 'aws-amplify';

export interface INotificationRepo {
  get(id: string): Promise<Notification>;
  update(notification: UpdateNotificationInput): Promise<Notification>;
  create(notification: CreateNotificationInput): Promise<Notification>;
  listNotificationsByUserId({
    userId,
    nextToken,
    limit,
  }: {
    userId: string;
    nextToken?: string;
    limit?: number;
  }): Promise<ListData<Notification>>;
}

export class RealNotificationRepo implements INotificationRepo {
  public async get(id: string): Promise<Notification> {
    const variables: GetNotificationQueryVariables = {
      id,
    };
    const res = (await API.graphql({
      query: getNotification,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: GetNotificationQuery };
    return res.data.getNotification as Notification;
  }

  public async create(notification: CreateNotificationInput): Promise<Notification> {
    const variables: CreateNotificationMutationVariables = {
      input: notification,
    };

    const res = (await API.graphql({
      query: createNotification,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: CreateNotificationMutation };

    return res.data.createNotification as Notification;
  }

  public async update(notification: UpdateNotificationInput): Promise<Notification> {
    const variables: UpdateNotificationMutationVariables = {
      input: notification,
    };

    const res = (await API.graphql({
      query: updateNotification,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: UpdateNotificationMutation };

    return res.data.updateNotification as Notification;
  }

  public async listNotificationsByUserId({
    userId,
    nextToken,
    limit,
  }: {
    userId: string;
    nextToken?: string;
    limit?: number;
  }): Promise<ListData<Notification>> {
    const variables: NotificationByUserIdPublicationDateTimeQueryVariables = {
      limit: limit || 100,
      nextToken,
      user_id: userId,
      sortDirection: ModelSortDirection.DESC,
    };
    const res = (await API.graphql({
      query: notificationByUserIdPublicationDateTime,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: NotificationByUserIdPublicationDateTimeQuery };

    const result: ListData<Notification> = {
      list: res.data.notificationByUserIdPublicationDateTime?.items as Notification[],
      nextToken: res.data.notificationByUserIdPublicationDateTime?.nextToken as string,
    };

    return result;
  }
}
